'use client'

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { useMedia } from 'react-use'

import { movieBannerImg } from '@Shared/constants/placeholder'
import { useNextOrderQuery } from '@Shared/hooks/use-next-order-query'
import useUserData from '@Shared/hooks/use-user-data'

import { Order as OrderType } from '@Schemas/domain/order'

import Icon from '@Components/icon'
import Image from '@Components/Image'

dayjs.extend(calendar)

type NextOrder = {
  eventName: string
  localDate: string
  images: {
    url: string
    type?: string | undefined
  }[]
}

const getNextOrder = (orders: OrderType[]) => {
  const now = dayjs(new Date())

  if (!Boolean(orders.length)) {
    return {} as NextOrder
  }

  const nextOrders = orders.filter((o) => {
    const date = dayjs(new Date(o?.sessions[0]?.localDate))
    const limitDateTime = date?.add(15, 'minutes')

    // Avoid geting session passports, then filter by date
    if (o?.sessions?.[0]?.theatre?.city?.toLowerCase() !== 'ingresso') {
      if (o?.sessions[0]?.localDate && !limitDateTime?.isBefore(now)) {
        return o
      }
    }
  })
  nextOrders.sort(
    (a, b) =>
      new Date(a?.sessions[0]?.localDate).getTime() -
      new Date(b?.sessions[0]?.localDate).getTime()
  )

  const order = nextOrders[0]

  const nextOrder = {
    eventName: order?.sessions?.[0]?.event?.name,
    localDate: order?.sessions?.[0]?.localDate,
    images: order?.sessions?.[0]?.images
  }

  return nextOrder
}

const Order = () => {
  const { user } = useUserData()
  const isMobile = useMedia('(max-width: 600px)', false)

  const { data: orders } = useNextOrderQuery(user?.id, {
    enabled: Boolean(user?.token && isMobile)
  })

  if (!orders) {
    return null
  }

  const nextOrder = getNextOrder(orders as OrderType[])

  if (!nextOrder) {
    return null
  }

  const sessionDate = dayjs(new Date(nextOrder?.localDate))
  const formattedDate = sessionDate.isValid()
    ? `${sessionDate.format('DD/MM HH:mm')}h`
    : ''
  const bannerImage = nextOrder?.images?.find(
    (item) => item.type === 'vertical'
  )

  const isOneDayBefore = dayjs().isSame(
    dayjs(sessionDate).subtract(1, 'day'),
    'day'
  )
  const isEventDate = dayjs().isSame(dayjs(sessionDate), 'day')
  const showOrder = isMobile && user?.token && (isOneDayBefore || isEventDate)

  return showOrder ? (
    <div className="mx-0 my-8">
      <a
        href={`${process.env.CONTROL_PANEL_URL}/meus-pedidos`}
        className="text-white no-underline"
      >
        <div className="mx-[10px] my-0 flex items-center rounded bg-[#252525] p-[10px]">
          <Image
            src={bannerImage?.url as string}
            fallback={movieBannerImg}
            width={40}
            height={60}
            alt={`imagem de capa do filme ${nextOrder?.eventName}`}
          />
          <div className="mr-[10px] w-[90%]">
            <h1 className="mb-1 ml-2 text-base">{nextOrder?.eventName}</h1>
            <p className="ml-2 text-xs">{formattedDate}</p>
          </div>
          <Icon
            src="/images/ticket.svg"
            className="[&_path]:fill-ing-blue-400 [&_svg]:w-6"
          />
        </div>
      </a>
    </div>
  ) : null
}

export default Order
