import { z } from 'zod'

export const CarouselPriorityListSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    carouselSlug: z.string(),
    priority: z.number(),
    type: z.string()
  })
)

export const mappedCarouselPriorityListSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    carouselSlug: z.string().or(z.array(z.string())),
    priority: z.number(),
    type: z.string()
  })
)

export type CarouselPriorityList = z.infer<typeof CarouselPriorityListSchema>
export type mappedCarouselPriorityList = z.infer<
  typeof mappedCarouselPriorityListSchema
>
