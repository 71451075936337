'use client'

import dynamic from 'next/dynamic'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import useCarouselPriorityQuery from '@Shared/hooks/use-carousel-priority-query'
import useCity from '@Shared/hooks/use-city'
import usePartnership from '@Shared/hooks/use-partnership'

import UOLAdContainer from '@Components/ad-banner/uol-ads'

const Carousel = dynamic(() => import('@Components/carousel'), { ssr: false })
const EventList = dynamic(
  () => import('@Components/carousel-components/event-list'),
  { ssr: false }
)
const NewsList = dynamic(
  () => import('@Components/carousel-components/news-list'),
  { ssr: false }
)
const FavoriteTheaterList = dynamic(
  () => import('@Components/carousel-components/favorite-theater-list'),
  { ssr: false }
)
const PromotionsCarousel = dynamic(
  () => import('@Components/carousel-components/promotions-carousel'),
  { ssr: false }
)

const CarouselContainer = ({
  slug,
  type
}: {
  slug: string | string[]
  type: string
}) => {
  const carouselComponents = {
    eventos: (slug) => <EventList slug={slug} />,
    news: () => <NewsList />,
    cinemas: () => <FavoriteTheaterList />,
    filmes: (slug: string) => <Carousel slug={slug} />,
    promocoes: () => <PromotionsCarousel />
  }

  return carouselComponents[type] ? carouselComponents[type](slug) : null
}

const CaroulselWrapper = () => {
  const [count, setCount] = useState(3)
  const partnership = usePartnership()

  const { city } = useCity()
  const { data } = useCarouselPriorityQuery(city.id, partnership.id)
  const carousels = data?.slice(0, count)

  const keepWatching = (data && Boolean(count < data?.length - 1)) ?? false
  const observerElem = useRef(null)

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries
      if (target.isIntersecting) {
        setCount((count) => (keepWatching ? count + 2 : count))
      }
    },
    [keepWatching]
  )

  useEffect(() => {
    const element = observerElem.current
    const option = { threshold: 0, rootMargin: '350px' }

    const observer = new IntersectionObserver(handleObserver, option)

    if (element) {
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [handleObserver])

  return (
    <>
      {carousels && (
        <div className="mb-[10px] ml-4">
          {carousels.map((carousel, index) => (
            <React.Fragment key={carousel?.id}>
              <CarouselContainer
                slug={carousel?.carouselSlug}
                type={carousel?.type}
              />
              {index === 0 && (
                <UOLAdContainer
                  className="py-3 lg:py-4"
                  id="div-gpt-ad-horizontal-atf"
                />
              )}
              {index === 2 && <UOLAdContainer id="div-gpt-ad-horizontal-btf" />}

              {/* This div is used by the marketing team to inject content via AD Service */}
              {index === 1 && (
                <div id="mkt__intervencao-wallpaper-estatico-video-mobile"></div>
              )}
            </React.Fragment>
          ))}
          <div ref={observerElem}></div>
        </div>
      )}
    </>
  )
}

export default CaroulselWrapper
