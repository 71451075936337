'use client'

import { ComponentProps, useEffect } from 'react'

import { getCarouselViewItemList, viewEvent } from '@Shared/helpers/tracking'
import { cn } from '@Shared/helpers/util'
import useCity from '@Shared/hooks/use-city'
import { useCollectionQuery } from '@Shared/hooks/use-collection-query'

import HighlightGrid from '@Components/highlight-grid'
import Skeleton from '@Components/skeleton/skeleton'
import Swiper from '@Components/swiper'

import Highlight from '../highlight'

type HightlightsProps = ComponentProps<'div'>

const highlightsLength = 3

const HighlightsSkeleton = () => (
  <Skeleton.Basic className="mx-auto mt-8 h-[360px] w-[calc(100%_-_32px)] max-w-screen-xl rounded-lg" />
)

const Highlights = ({ className, ...props }: HightlightsProps) => {
  const { city } = useCity()

  const { data: collection, isLoading } = useCollectionQuery({
    slug: 'destaque',
    options: {
      enabled: Boolean(city?.id)
    }
  })

  useEffect(() => {
    collection &&
      viewEvent('view_item_list', getCarouselViewItemList(collection))
  }, [collection])

  const hasEvents = Boolean(collection?.events?.length)

  const isCarousel = false //TODO: change when the API gets if the highlight is a carousel or grid

  const initialHighlights = collection?.events?.slice(0, highlightsLength) ?? []

  const highlights =
    initialHighlights.length === 2 ? [initialHighlights[0]] : initialHighlights

  const isSingleHighlight = highlights.length === 1

  return isLoading ? (
    <HighlightsSkeleton />
  ) : (
    <div
      id="highlight-banner"
      className={cn(
        'highlight-banner pb-3 lg:pb-4',
        (isCarousel || isSingleHighlight) && 'bg-ing-neutral-700',
        className
      )}
      {...props}
    >
      {hasEvents &&
        (isCarousel ? (
          <Swiper
            shadows={false}
            options={{
              arrows: Boolean(collection?.events?.length > 1),
              pagination: false
            }}
          >
            {collection.events.map((movie, index) => (
              <div key={movie.id} className="mx-auto max-w-screen-xl">
                <Highlight
                  movie={movie}
                  index={index}
                  isCarousel
                  className='h-[250px] max-w-screen-xl before:rounded-none after:rounded-none lg:h-[360px] xl:[&_>_a]:after:absolute xl:[&_>_a]:after:bottom-0 xl:[&_>_a]:after:right-0 xl:[&_>_a]:after:top-0 xl:[&_>_a]:after:z-10 xl:[&_>_a]:after:block xl:[&_>_a]:after:h-full xl:[&_>_a]:after:w-[290px] xl:[&_>_a]:after:bg-gradient-to-l xl:[&_>_a]:after:from-ing-neutral-700 xl:[&_>_a]:after:to-ing-neutral-700/0 xl:[&_>_a]:after:content-[""]'
                />
              </div>
            ))}
          </Swiper>
        ) : (
          <HighlightGrid.Root
            className={cn(
              'max-w-screen-xl px-4',
              isSingleHighlight && 'px-0 pt-0'
            )}
          >
            {highlights.map((movie, index) => (
              <HighlightGrid.Item key={movie.id}>
                <Highlight
                  movie={movie}
                  index={index}
                  className={cn(
                    isSingleHighlight &&
                      'before:rounded-none after:rounded-none xl:[&_>_a]:after:absolute xl:[&_>_a]:after:bottom-0 xl:[&_>_a]:after:right-0 xl:[&_>_a]:after:top-0 xl:[&_>_a]:after:z-10 xl:[&_>_a]:after:block xl:[&_>_a]:after:h-full xl:[&_>_a]:after:w-[290px] xl:[&_>_a]:after:bg-gradient-to-l xl:[&_>_a]:after:from-ing-neutral-700 xl:[&_>_a]:after:to-ing-neutral-700/0 xl:[&_>_a]:after:content-[""] [&_img]:rounded-none'
                  )}
                />
              </HighlightGrid.Item>
            ))}
          </HighlightGrid.Root>
        ))}
    </div>
  )
}
export default Highlights
