import { z } from 'zod'

const OrderStatusSchema = z.object({
  value: z.string(),
  description: z.string()
})

const ImageSchema = z.object({
  url: z.string(),
  type: z.string().optional()
})

const EventTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  alias: z.string()
})

export const OrderEventSchema = z.object({
  id: z.string(),
  name: z.string(),
  formattedName: z.string(),
  type: EventTypeSchema,
  rating: z.string(),
  genre: z.string(),
  distributor: z.string().optional()
})

const OrderTheaterSchema = z.object({
  id: z.string(),
  name: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  state: z.string(),
  mapLink: z.string(),
  city: z.string(),
  neighborhood: z.string(),
  formattedCity: z.string()
})

const LocationSchema = z.object({
  id: z.string(),
  name: z.string()
})

const LocalDateFormattedSchema = z.object({
  dayOfWeek: z.string(),
  dayAndMonth: z.string(),
  hour: z.string(),
  year: z.string()
})

const SubtitlesSchema = z.object({
  id: z.number(),
  name: z.string()
})

const DeliverySchema = z.object({
  id: z.string(),
  printType: z.string(),
  printUrl: z.string(),
  searchCode: z.string(),
  print: z.boolean(),
  printMessage: z.string(),
  mobile: z.boolean(),
  boxOfficeMessage: z.string().optional(),
  scannerless: z.boolean(),
  passBook: z.boolean(),
  dataMatrix: z.boolean(),
  hasRegisteredDeviceId: z.boolean(),
  hasMatchedDeviceId: z.boolean()
})

const TicketTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  price: z.number(),
  service: z.number(),
  tax: z.number(),
  total: z.number(),
  maxQuantity: z.number(),
  help: z.string(),
  order: z.number(),
  boxOfficeId: z.string(),
  boxOfficeName: z.string(),
  externalCode: z.string(),
  externalValue: z.number()
})

const SeatSchema = z.object({
  label: z.string(),
  type: z.string(),
  typeDescription: z.string()
})

const ReceiptSchema = z.object({
  number: z.string(),
  taxCode: z.string(),
  barCode: z.string(),
  barCodeRendered: z.string(),
  dataMatrix: z.string()
})

const TicketSchema = z.object({
  type: TicketTypeSchema,
  seat: SeatSchema.optional(),
  receipt: ReceiptSchema
})

const ProductItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  price: z.number(),
  quantity: z.number()
})

const ProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  image: ImageSchema.optional(),
  quantity: z.number(),
  price: z.number(),
  service: z.number(),
  items: z.array(ProductItemSchema).optional()
})

const OrderSessionSchema = z.object({
  id: z.string(),
  event: OrderEventSchema,
  theatre: OrderTheaterSchema,
  location: LocationSchema,
  date: z.string(),
  localDate: z.string(),
  localDateFormatted: LocalDateFormattedSchema,
  timeZone: z.string(),
  dateFormatted: z.string(),
  typeDescription: z.array(z.string()),
  subtitles: SubtitlesSchema,
  delivery: DeliverySchema,
  tickets: z.array(TicketSchema),
  products: z.array(ProductSchema).optional(),
  total: z.number(),
  images: z.array(ImageSchema),
  guid: z.string(),
  type: z.string()
})

const PaymentSchema = z.object({
  cardType: z.string(),
  type: z.string(),
  bin: z.string(),
  value: z.number(),
  password: z.string().optional(),
  giftcardType: z.string().optional()
})

const DiscountSchema = z.object({
  id: z.string(),
  description: z.string(),
  value: z.number()
})

const VoucherSchema = z.object({
  campaignName: z.string(),
  campaignId: z.string(),
  views: z.array(z.string()),
  totalViews: z.number()
})

const RefundSchema = z.object({
  enabled: z.boolean(),
  canCancel: z.boolean(),
  refundType: z.string(),
  value: z.number(),
  reasonType: z.string()
})

const LastStepSchema = z.object({
  status: z.string()
})

export const OrderSchema = z.object({
  id: z.string(),
  date: z.string(),
  dateFormatted: z.string(),
  status: OrderStatusSchema,
  sessions: z.array(OrderSessionSchema),
  payments: z.array(PaymentSchema),
  discounts: z.array(DiscountSchema),
  total: z.number(),
  lastStep: LastStepSchema,
  name: z.string(),
  cpf: z.string(),
  userName: z.string(),
  phone: z.string(),
  vouchers: z.array(VoucherSchema),
  partnership: z.string(),
  refund: RefundSchema,
  userType: z.string(),
  renewToken: z.string(),
  dynamicLink: z.string()
})

export type Order = z.infer<typeof OrderSchema>
