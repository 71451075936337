import { Slot } from '@radix-ui/react-slot'
import { ComponentProps, Children } from 'react'

import { cn } from '@Shared/helpers/util'

type RootProps = ComponentProps<'div'>
type ItemProps = ComponentProps<'div'> & { asChild?: boolean }

const Root = ({ children, className, ...props }: RootProps) => {
  const childrenCount = Children.count(children)

  return (
    <div
      className={cn(
        'mx-auto grid w-full grid-cols-2 gap-2 sm:gap-3 lg:grid-cols-[3fr_1.5fr] lg:gap-4',
        className,
        childrenCount === 1 && 'lg:block'
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const Item = ({ className, asChild, ...props }: ItemProps) => {
  const Component = asChild ? Slot : 'div'

  return (
    <Component
      className={cn(
        'relative h-[88px] rounded-lg first:col-span-2 first:h-[200px] sm:h-[145px] sm:first:h-[250px] lg:h-[150px] lg:first:col-span-1 lg:first:row-span-2 lg:first:h-[320px]',
        className
      )}
      {...props}
    />
  )
}

const HighlightGrid = {
  Root,
  Item
}

export default HighlightGrid
